var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"activity-item"},[(
      _vm.item.type === 'MINT' ||
      _vm.item.type === 'LISTING' ||
      _vm.item.type === 'BID_SENT' ||
      _vm.item.type === 'SELL'
    )?_c('div',{staticClass:"activity-image",staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.$router.push({
        path: _vm.nftLink(
          _vm.item.nftToken.blockChainId || _vm.item.nftToken.id,
          _vm.item.nftToken.currency,
          _vm.getContractAddressByCurrency(
            _vm.item.nftToken.currency,
            _vm.item.nftToken.blockChainId
          )
        ),
      })}}},[_c('TokenContentType',{attrs:{"file-url":_vm.item.nftToken.fileUrl,"token-type":_vm.item.nftToken.type,"preview-url":_vm.item.nftToken.previewUrl,"show-audio-player":false}})],1):_vm._e(),(_vm.item.type === 'SUBSCRIBE')?_c('div',{staticClass:"activity-image"},[_c('img',{attrs:{"src":_vm.appointAvatar(_vm.item.user.userInfo.avatarUrl, _vm.item.user.id)}})]):_vm._e(),_c('div',{staticClass:"activity-info"},[(_vm.item.type === 'SUBSCRIBE')?_c('div',{staticClass:"activity-desc"},[_vm._v(" You have subscribed to "),_c('UsernameLink',{attrs:{"username":_vm.item.user.nickname,"user-address":_vm.item.user.address,"user-id":_vm.item.user.id}})],1):_vm._e(),(
        _vm.item.type === 'MINT' ||
        _vm.item.type === 'LISTING' ||
        _vm.item.type === 'SELL'
      )?_c('router-link',{staticClass:"activity-title",attrs:{"to":_vm.nftLink(
          _vm.item.nftToken.blockChainId || _vm.item.nftToken.id,
          _vm.item.nftToken.currency,
          _vm.getContractAddressByCurrency(
            _vm.item.nftToken.currency,
            _vm.item.nftToken.blockChainId
          )
        )}},[_vm._v(_vm._s(_vm.item.nftToken.title))]):_vm._e(),(_vm.item.type === 'MINT')?_c('div',{staticClass:"activity-desc"},[_vm._v(" Minted by "),_c('router-link',{attrs:{"to":_vm.profileLink(_vm.item.nftToken.creator.id)}},[_vm._v("You")])],1):_vm._e(),(_vm.item.type === 'SELL')?_c('div',{staticClass:"activity-desc"},[_vm._v(" Sold by "),_c('router-link',{attrs:{"to":_vm.profileLink(_vm.item.nftToken.creator.id)}},[_vm._v("You")])],1):_vm._e(),(_vm.item.type === 'LISTING')?_c('div',{staticClass:"activity-desc"},[_vm._v(" Listed by "),_c('router-link',{attrs:{"to":_vm.profileLink(_vm.item.nftToken.owner.id)}},[_vm._v("You")]),_vm._v(" for "),_c('span',[_vm._v(_vm._s(_vm.formatNumber(_vm.item.nftToken.activeAuction.minBidPrice))+" "+_vm._s(_vm.item.nftToken.activeAuction.currency))])],1):_vm._e(),(_vm.item.type === 'BID_SENT')?_c('div',{staticClass:"activity-desc"},[_c('router-link',{attrs:{"to":_vm.profileLink(_vm.item.nftToken.creator.id)}},[_vm._v("You")]),_vm._v(" offered "),_c('span',[_vm._v(_vm._s(_vm.formatNumber(_vm.item.rate.price))+" "+_vm._s(_vm.item.rate.currency))]),_vm._v(" for 1 edition editions each ")],1):_vm._e(),_c('div',{staticClass:"activity-desc"},[_vm._v(" "+_vm._s(_vm.timestampToCustomString(_vm.item.created, "long-time"))+" ")])],1),(_vm.item.type === 'MINT' || _vm.item.type === 'LISTING')?_c('a',{staticClass:"svg blank",attrs:{"href":_vm.appointExplorerLink(_vm.item.nftToken.hash, _vm.item.nftToken.currency, 'tx'),"target":"_blank"}},[_c('svg',[_c('use',{attrs:{"xlink:href":"#svg-blank"}})])]):_vm._e(),(_vm.item.type === 'BID_SENT')?_c('a',{staticClass:"svg blank",attrs:{"href":_vm.appointExplorerLink(_vm.item.rate.hash, _vm.item.rate.currency, 'tx'),"target":"_blank"}},[_c('svg',[_c('use',{attrs:{"xlink:href":"#svg-blank"}})])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }