<template>
  <div class="activity-item">
    <div
      class="activity-image"
      style="cursor: pointer"
      v-if="
        item.type === 'MINT' ||
        item.type === 'LISTING' ||
        item.type === 'BID_SENT' ||
        item.type === 'SELL'
      "
      @click="
        $router.push({
          path: nftLink(
            item.nftToken.blockChainId || item.nftToken.id,
            item.nftToken.currency,
            getContractAddressByCurrency(
              item.nftToken.currency,
              item.nftToken.blockChainId
            )
          ),
        })
      "
    >
      <TokenContentType
        :file-url="item.nftToken.fileUrl"
        :token-type="item.nftToken.type"
        :preview-url="item.nftToken.previewUrl"
        :show-audio-player="false"
      />
    </div>

    <div class="activity-image" v-if="item.type === 'SUBSCRIBE'">
      <img :src="appointAvatar(item.user.userInfo.avatarUrl, item.user.id)" />
    </div>

    <div class="activity-info">
      <div class="activity-desc" v-if="item.type === 'SUBSCRIBE'">
        You have subscribed to
        <UsernameLink
          :username="item.user.nickname"
          :user-address="item.user.address"
          :user-id="item.user.id"
        />
      </div>

      <router-link
        class="activity-title"
        v-if="
          item.type === 'MINT' ||
          item.type === 'LISTING' ||
          item.type === 'SELL'
        "
        :to="
          nftLink(
            item.nftToken.blockChainId || item.nftToken.id,
            item.nftToken.currency,
            getContractAddressByCurrency(
              item.nftToken.currency,
              item.nftToken.blockChainId
            )
          )
        "
        >{{ item.nftToken.title }}</router-link
      >

      <div class="activity-desc" v-if="item.type === 'MINT'">
        Minted by
        <router-link :to="profileLink(item.nftToken.creator.id)"
          >You</router-link
        >
      </div>

      <div class="activity-desc" v-if="item.type === 'SELL'">
        Sold by
        <router-link :to="profileLink(item.nftToken.creator.id)"
          >You</router-link
        >
      </div>

      <div class="activity-desc" v-if="item.type === 'LISTING'">
        Listed by
        <router-link :to="profileLink(item.nftToken.owner.id)">You</router-link>
        for
        <span
          >{{ formatNumber(item.nftToken.activeAuction.minBidPrice) }}
          {{ item.nftToken.activeAuction.currency }}</span
        >
      </div>

      <div class="activity-desc" v-if="item.type === 'BID_SENT'">
        <router-link :to="profileLink(item.nftToken.creator.id)"
          >You</router-link
        >
        offered
        <span
          >{{ formatNumber(item.rate.price) }} {{ item.rate.currency }}</span
        >
        for 1 edition editions each
      </div>

      <div class="activity-desc">
        {{ timestampToCustomString(item.created, "long-time") }}
      </div>
    </div>

    <a
      v-if="item.type === 'MINT' || item.type === 'LISTING'"
      :href="
        appointExplorerLink(item.nftToken.hash, item.nftToken.currency, 'tx')
      "
      target="_blank"
      class="svg blank"
    >
      <svg><use xlink:href="#svg-blank" /></svg>
    </a>

    <a
      v-if="item.type === 'BID_SENT'"
      :href="appointExplorerLink(item.rate.hash, item.rate.currency, 'tx')"
      target="_blank"
      class="svg blank"
    >
      <svg><use xlink:href="#svg-blank" /></svg>
    </a>
  </div>
</template>

<script>
import TokenContentType from "@/components/common/TokenContentType";
import TimestampToCustomStringMixin from "@/mixins/timestampToCustomStringMixin";
import formatNumberMixin from "@/mixins/formatNumberMixin";
import appointExplorerLinkMixin from "@/mixins/appointExplorerLinkMixin";
import appointAvatarMixin from "@/mixins/appointAvatarMixin";
import UsernameLink from "@/components/common/UsernameLink";
import getContractAddressByCurrencyMixin from "@/mixins/getContractAddressByCurrencyMixin";
import useLinkMixin from "@/mixins/useLinkMixin";

export default {
  name: "SheetItem",
  mixins: [
    TimestampToCustomStringMixin,
    useLinkMixin,
    formatNumberMixin,
    appointExplorerLinkMixin,
    appointAvatarMixin,
    getContractAddressByCurrencyMixin,
  ],
  components: { UsernameLink, TokenContentType },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>
