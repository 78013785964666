<template>
  <div>
    <div id="intro">
      <div class="cover" v-if="getBanner">
        <img :src="getBanner" />
      </div>

      <div class="section">
        <div class="meta">
          <div class="userpic">
            <img :src="getAvatar" />
          </div>
        </div>
      </div>
    </div>

    <div id="subintro" class="section">
      <h1>Activity</h1>

      <div class="tabs">
        <div
          class="tab"
          :class="{ active: tab === 'all' }"
          @click="toggleTab('all')"
        >
          All
        </div>
        <div
          class="tab"
          :class="{ active: tab === 'following' }"
          @click="toggleTab('following')"
        >
          Following
        </div>
        <div
          class="tab"
          :class="{ active: tab === 'bids' }"
          @click="toggleTab('bids')"
        >
          Bids
        </div>
      </div>
    </div>

    <div id="activity" class="section">
      <div>
        <SheetItem v-for="(item, index) in items" :key="index" :item="item" />
      </div>

      <NoRecordsFound v-if="!items.length && disableLoading" />

      <ScrollLoader
        :loader-method="appointLoaderMethod"
        :loader-disable="disableLoading"
      />
    </div>
  </div>
</template>

<script>
import api from "@/api/api";
import resetPrevLoadingMixin from "@/mixins/resetPrevLoadingMixin";
import SheetItem from "@/components/common/SheetItem";
import NoRecordsFound from "@/components/common/NoRecordsFound";
import { mapGetters } from "vuex";
import useLinkMixin from "@/mixins/useLinkMixin";

export default {
  name: "Activity",
  components: { NoRecordsFound, SheetItem },
  mixins: [resetPrevLoadingMixin, useLinkMixin],
  props: {
    tab: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    page: 0,
    size: 12,
    items: [],
    disableLoading: false,
    type: null,
  }),
  computed: {
    ...mapGetters({
      getAvatar: "user/getAvatar",
      getBanner: "user/getBanner",
    }),
    appointLoaderMethod() {
      switch (this.tab) {
        case "all":
          return this.getActivity;
        case "following":
          return this.getActivityByType;
        case "history":
          return this.getActivityByType;
        case "bids":
          return this.getActivityByType;
        default:
          return null;
      }
    },
  },
  methods: {
    toggleTab(tab) {
      if (this.tab !== tab) {
        this.$router.push(this.activityLink(tab));
        this.resetPrevLoading();
      }
    },
    async getActivity() {
      try {
        const response = await api.getActivity({
          page: this.page++,
          size: this.size,
          sort: "created,desc",
        });
        this.items = [...this.items, ...response.content];
        this.disableLoading = response.content.length < this.size;
      } catch (e) {
        this.disableLoading = true;
      }
    },
    async getActivityByType() {
      try {
        let type = null;
        switch (this.tab) {
          case "following":
            type = "SUBSCRIBE";
            break;
          case "bids":
            type = "BID_SENT";
            break;
          default:
            return null;
        }
        const response = await api.getActivityByType({
          page: this.page++,
          size: this.size,
          type,
          sort: "created,desc",
        });
        this.items = [...this.items, ...response.content];
        this.disableLoading = response.content.length < this.size;
      } catch (e) {
        this.disableLoading = true;
      }
    },
  },
};
</script>
